.dashboard_error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.dashboard_error h1 {
    font-size: 60px !important;
    margin: 0 0 10px 0 !important;
    font-family: 'Montserrat';
    font-weight: 900 !important;
}

.dashboard_error p {
    font-size: 20px !important;
    margin: 0 0 10px 0 !important;
    font-family: 'Montserrat';
    text-align: center;
}

.dashboard_error button {
    margin-top: 40px
}