.ant-btn svg {
  margin: 0 !important;
}

.delete-action {
  margin-left: 5px;
}

.logo-program-table {
  height: 100%;
  max-height: 50px;
}

.title-program {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.description-program {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.subtitle-program {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.modal-program .modal-div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.modal-antd-program {
  width: 500px !important;
}

.ant-picker {
  height: 40px;
  border-radius: 6px !important;
}

.input-div.date-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-div.date-div .date-input-div {
  width: 49%;
}

.input-div.date-div .hour-start-input-div {
  width: 49%;
}

.speakers-moderators-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.speakers-moderators-div .moderator-input-div {
  width: 49%;
}

.speakers-moderators-div .speaker-input-div {
  width: 49%;
}

.radio-type {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.radio-type .ant-radio-button-wrapper {
  width: 49%;
  height: 60px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-type .ant-radio-button-wrapper img {
  height: 60px !important;
}

.radio-type
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: rgba(0, 145, 149, 0.4) !important;
  border-color: rgba(0, 145, 149, 0.4) !important;
}

.radio-type
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background: rgba(0, 145, 149, 0.6) !important;
}

.ql-editor {
  height: 80px !important;
  font-family: "Montserrat";
}

.program_item {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 21, 41, 0.05);
  -moz-box-shadow: 0px 0px 10px 2px rgba(0, 21, 41, 0.05);
  box-shadow: 0px 0px 10px 0px rgba(0, 21, 41, 0.05);
  border: 2px solid rgba(0, 21, 41, 0.05);
}

.program_item p {
  margin: 0 !important;
}

.program_item svg {
  font-size: 30px;
}

.edit-action svg {
  color: #c1c1c1;
  font-size: 25px !important;
  margin-right: 10px;
}

.edit-action svg:hover {
  color: #001529;
}

.link-action svg {
  margin-top: 5px;
  color: #c1c1c1;
  font-size: 25px !important;
}

.link-action svg:hover {
  color: #001529;
  font-size: 25px !important;
}

.delete-action svg {
  color: #c1c1c1;
  font-size: 25px;
}

.delete-action svg:hover {
  color: #ec224e;
}
