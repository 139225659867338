@import url("https://fonts.googleapis.com/css2?family=MuseoModerno:wght@800&display=swap");

.ant-layout .ant-layout-sider {
  background-color: rgba(131, 0, 81, 1) !important;
  color: #f0ac06 !important;
}

.ant-menu-dark .ant-menu-item-selected {
  background-color: #f0ac06 !important;
}

.ant-menu-dark {
  background-color: rgba(131, 0, 81, 1) !important;
}

.ant-layout-header {
  background-color: rgba(131, 0, 81, 1) !important;
}

.admin_layout_content {
  max-height: calc(100vh - 90px);
  overflow: auto;
}

.admin_layout .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  height: 45px;
}

.admin_layout .logo img {
  height: 100%;
  width: 100%;
}

.user_header {
  color: #fff;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_header .anticon {
  font-size: 20px;
  margin-right: 8px;
}

.logout_header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout_header button {
  color: #fff;
}

.logout_header button:hover {
  opacity: 0.7;
  color: #fff !important;
}

.logout_header .anticon {
  font-size: 20px;
}

.icon-menu {
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
