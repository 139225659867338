.preview_qrcode {
  width: 100%;
  height: 160px;
  top: 0;
  bottom: 0;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

.preview_qrcode img {
  width: auto;
  height: 90%;
}

.challenge_item {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 21, 41, 0.05);
  -moz-box-shadow: 0px 0px 10px 2px rgba(0, 21, 41, 0.05);
  box-shadow: 0px 0px 10px 0px rgba(0, 21, 41, 0.05);
  border: 2px solid rgba(0, 21, 41, 0.05);
}

.challenge_item p {
  margin: 0 !important;
}
